import React from 'react'
import '../styles/hover.css'

// A 'hover' component i.e. text that gives a tooltip when you hover over it.
class Hover extends React.Component {
  render() {
    return(
      <React.Fragment>
        <div class='hover'>
          {this.props.text}
          <span class='tooltiptext'>
            <small>
              {this.props.children}
            </small>
          </span>
        </div>
      </React.Fragment>
    );
  }
}

export default Hover
