import React from 'react'
import { authenticatedLoginContext } from './authenticatedLoginContext'
import DeletedVaultsTable from './deletedvaultstable'

class DeletedVaultsPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      authenticatedUsername: 'unidentified human'
    };
  }

  componentDidMount() {
    this.setState({
      authenticatedUsername: this.context.username
    });
  }

  render() {
    if (this.state.authenticatedUser === 'unidentified human') {
      return(<p>Loading...</p>);
    } else {
      return(
        <React.Fragment>
          <h3>Deleted vaults</h3>
          <br />

          <p>Your deleted vaults are listed below.</p>
          { this.context.admin 
            ? <p>As you are an admin, all deleted vaults in the system are listed below.</p>
            : <p>Please contact itsupport@york.ac.uk if you require further details about a deleted vault.</p>
          }
          <p>Hover over table elements more details.</p>
          <DeletedVaultsTable />
        </React.Fragment>);
    }
  }
}

DeletedVaultsPage.contextType = authenticatedLoginContext;

export default DeletedVaultsPage
