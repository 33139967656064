import React from "react"
import { Link } from "gatsby"

class DeletedVaultsAlert extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      dismissed: false
    };

    this.dismiss = this.dismiss.bind(this);
  }

  dismiss() {
    this.setState({
      dismissed: true
    });
  }

  render() {
    const { dismissed } = this.state;

    if (dismissed) {
      return null;
    } else {
      return(
        <React.Fragment>
          <div class="c-alert c-alert--info" role="alert">
            <div class="c-alert__content">
              <p><strong>It's looking a bit empty round here...</strong></p>
              <p>You don't currently have any deleted vaults.</p>
              <p>Vaults are deleted once they reach their expiry date (you can modify this at any time by going to a vault's details page).</p>
              <p>When a vault is deleted, all its data is removed from storage, but a record for it will be kept it for your own use.</p>
              <button class="c-btn c-btn--secondary c-btn--medium" onClick={this.dismiss}>Close</button>
            </div>
          </div>
        </React.Fragment>);
    }
  }
}

export default DeletedVaultsAlert
