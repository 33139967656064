import React from "react"
import DeletedVaultsAlert from "./deletedvaultsalert"
import { Link } from "gatsby"
import Spinner from "./spinner"
import { Auth } from "aws-amplify"
import { authenticatedLoginContext } from './authenticatedLoginContext'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { formatCreationDate } from '../helpers/helpers'
import { faLongArrowAltRight } from '@fortawesome/free-solid-svg-icons'
import Hover from './hover'

class DeletedVaultsTable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      error: null,
      loaded: false,
      items: []
    };
  }

  async componentDidMount() {
    Auth.currentSession()
      .then(async data => {

        // If the authenticated user is an admin, fetch all vaults, else just
        // fetch the vaults that the authenticated user owns.
        if (this.context.admin === true) {
          var url = `${process.env.GATSBY_API_URL}/vaults`
        } else {
          url = `${process.env.GATSBY_API_URL}/vaults?username=${this.context.username}`
        }

        var res = await fetch(
          url,
          {
            method: 'GET',
            mode: 'cors',
            headers: new Headers({
              'Authorization': 'Bearer ' + data.idToken.jwtToken
            })
          }
        );

        const vaults = await res.json();

        // Don't bother handling undefined "deleted" value, as we just consider this to mean it's not deleted.
        var deleted_vaults = vaults.filter(vault => vault.deleted === true)

        this.setState({
          loaded: true,
          items: deleted_vaults,
          username: this.context.username
        });

      })
      .catch(err => console.log(err));
  }

  render() {
    const { error, loaded, items } = this.state;

    if (error) {
      return <div>Error</div>;
    } else if (!loaded) {
      return <Spinner />;
    } else {
      var count = items.length;

      if (count === 0) {
	return <DeletedVaultsAlert />;
      } else {
	return(
	  <React.Fragment>
	    <table>
	      <thead>
		<tr>
		  <th>Vault</th>
		  <th>Lifetime</th>
		  <th>Department</th>
		</tr>
	      </thead>
	      <tbody>
		{
		  items.map((vault, index) => (
		    <tr key={index}>
		      <td key="0">
                        <Hover text={vault.title}>
                          Description: {vault.description}
                        </Hover>
                      </td>
                      <td key="1">
                        {formatCreationDate(vault.creationDateSeconds)}&nbsp;&nbsp;&nbsp;
                        <FontAwesomeIcon icon={faLongArrowAltRight} />&nbsp;&nbsp;&nbsp;
                        {formatCreationDate(vault.expiryDateSeconds)}
                      </td>
                      <td key="2">
                        <Hover text={vault.department}>
                          Owners at the point when the vault was deleted:
                          &nbsp;
                          <ul>
                            {
                              vault.owners.map((owner, index) => (
                                <li>{owner}&nbsp;</li>
                              ))
                            }
                          </ul>
                        </Hover>
                      </td>
		    </tr>
		  ))
		}
	      </tbody>
	    </table>
	  </React.Fragment>);
      }
    }
  }
}

DeletedVaultsTable.contextType = authenticatedLoginContext;

export default DeletedVaultsTable
